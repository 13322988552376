import { GoodsDetail } from "@/components/pages/Goods/Detail"
import { graphql } from "gatsby"

export default GoodsDetail

export const query = graphql`
    query PageGoodsDetail($slug: String!) {
        contentfulSite {
            siteHeroImage {
                gatsbyImageData(resizingBehavior: FILL)
            }
        }
        contentfulGoods(slug: { eq: $slug }) {
            ...GoodsInformation
            goodsImages {
                gatsbyImageData
            }
            goodsContent {
                raw
            }
        }
    }
`
