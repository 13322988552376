import * as React from "react"
import { SectionWrap } from "@/components/sections/SectionWrap"
import { ArticleRichTextContent } from "@/components/ArticleRichTextContent"
import { GlobalLayout } from "@/components/GlobalLayout"
import { Head } from "@/components/Head"
import { ArticleSlider } from "@/components/Sliders"
import { PageProps } from "gatsby"
import { TitleWithBgImg } from "@/components/TitleWithBgImg"
import { useImage } from "@/hooks/useImage"
import { PageGoodsDetailQuery } from "@root/types/graphql-types"
import {
    ArticleContent,
    ArticleSection,
    ArticleTitle,
    ArticleWrapper,
} from "@/components/Layouts/Article"
import { useExpandImg } from "@/hooks/useExpandImg"

type Props = PageProps<PageGoodsDetailQuery>

export const GoodsDetail: React.FC<Props> = ({ data }) => {
    const { openFromEvent, ExpandImg } = useExpandImg()
    const { getImageProps, Image } = useImage()

    return (
        <GlobalLayout
            Head={
                <Head
                    title={data.contentfulGoods?.goodsTitle || undefined}
                    description={`${data.contentfulGoods?.goodsTitle}の詳細ページ`}
                    noIndex
                />
            }
            CoverComponent={<ExpandImg />}
        >
            <SectionWrap
                Title={
                    <TitleWithBgImg
                        title="GOODS INFO"
                        BgImage={
                            <Image
                                {...getImageProps(
                                    data.contentfulSite?.siteHeroImage
                                        ?.gatsbyImageData
                                )}
                            />
                        }
                    />
                }
            >
                <ArticleWrapper>
                    <ArticleTitle>
                        {data.contentfulGoods?.goodsTitle || ""}
                    </ArticleTitle>
                    <ArticleContent
                        releaseDate={new Date(data.contentfulGoods?.updatedAt)}
                        Header={
                            <>
                                {data.contentfulGoods?.goodsImages ? (
                                    <ArticleSlider
                                        openExpandImg={openFromEvent}
                                        Images={data.contentfulGoods.goodsImages.map(
                                            (image, i) => (
                                                <Image
                                                    {...getImageProps(
                                                        image?.gatsbyImageData,
                                                        {
                                                            alt: `${
                                                                data
                                                                    .contentfulGoods
                                                                    ?.goodsTitle
                                                            }のイメージ${
                                                                i + 1
                                                            }`,
                                                        }
                                                    )}
                                                />
                                            )
                                        )}
                                    />
                                ) : (
                                    <p>この商品には画像がありません</p>
                                )}
                                <div className="p-article__goods-info">
                                    <div className="p-article__goods-info-link">
                                        {data.contentfulGoods?.goodsUrl ? (
                                            <a
                                                href={
                                                    data.contentfulGoods
                                                        .goodsUrl
                                                }
                                            >
                                                ご購入はこちら
                                            </a>
                                        ) : (
                                            <span
                                                style={{
                                                    background: "red",
                                                    color: "white",
                                                    borderRadius: "5px",
                                                    padding: "5px",
                                                    fontSize: "0.6em",
                                                }}
                                            >
                                                会場限定販売
                                            </span>
                                        )}
                                    </div>
                                    <div className="p-article__goods-info-price">
                                        {parseInt(
                                            data.contentfulGoods?.goodsPrice ||
                                                "?"
                                        ).toLocaleString()}
                                        円
                                    </div>
                                </div>
                            </>
                        }
                    >
                        <ArticleSection>
                            {data.contentfulGoods?.goodsContent && (
                                <ArticleRichTextContent
                                    imgOnClick={openFromEvent}
                                    richTextData={
                                        data.contentfulGoods.goodsContent.raw &&
                                        JSON.parse(
                                            data.contentfulGoods.goodsContent
                                                .raw
                                        )
                                    }
                                />
                            )}
                        </ArticleSection>
                    </ArticleContent>
                </ArticleWrapper>
            </SectionWrap>
        </GlobalLayout>
    )
}
